/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfListOfComboboxItemDto } from "../models/AjaxResponseOfListOfComboboxItemDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class SegmentsAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfListOfComboboxItemDto
   * @throws ApiError
   */
  public getSegmentsCombo({
    segmentId,
  }: {
    segmentId?: number;
  }): CancelablePromise<AjaxResponseOfListOfComboboxItemDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/segments/options",
      query: {
        segmentId: segmentId,
      },
    });
  }
}
