/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfPerfilClickModel } from "../models/AjaxResponseOfPerfilClickModel";
import type { AjaxResponseOfString } from "../models/AjaxResponseOfString";
import type { PerfilClickModel } from "../models/PerfilClickModel";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class PerfilClicksAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfString
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody?: PerfilClickModel;
  }): CancelablePromise<AjaxResponseOfString> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/perfil-click",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public delete({ id }: { id?: string }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/front-end/v1/perfil-click",
      query: {
        id: id,
      },
    });
  }

  /**
   * @returns AjaxResponseOfPerfilClickModel
   * @throws ApiError
   */
  public get({
    id,
  }: {
    id?: string;
  }): CancelablePromise<AjaxResponseOfPerfilClickModel> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/perfil-click",
      query: {
        id: id,
      },
    });
  }

  /**
   * @returns AjaxResponseOfString
   * @throws ApiError
   */
  public update({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PerfilClickModel;
  }): CancelablePromise<AjaxResponseOfString> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/front-end/v1/perfil-click/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }
}
