/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto } from "../models/AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class PermissionsAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto
   * @throws ApiError
   */
  public getAllPermissions(): CancelablePromise<AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/permissions",
    });
  }
}
