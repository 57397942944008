/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfGetUserForEditOutput } from "../models/AjaxResponseOfGetUserForEditOutput";
import type { AjaxResponseOfPagedResultDtoOfUserListForSubscriptionDto } from "../models/AjaxResponseOfPagedResultDtoOfUserListForSubscriptionDto";
import type { AjaxResponseOfUserLoginInfoDto } from "../models/AjaxResponseOfUserLoginInfoDto";
import type { CreateOrUpdateUserInput } from "../models/CreateOrUpdateUserInput";
import type { RegistrationDto } from "../models/RegistrationDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class UserAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfUserLoginInfoDto
   * @throws ApiError
   */
  public completeRegistration({
    requestBody,
  }: {
    requestBody?: RegistrationDto;
  }): CancelablePromise<AjaxResponseOfUserLoginInfoDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/user/complete-registration",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfPagedResultDtoOfUserListForSubscriptionDto
   * @throws ApiError
   */
  public getUsersFromSubscription({
    filter,
    subscriptionId,
    permissions,
    role,
    onlyLockedUsers,
    sorting,
    maxResultCount,
    skipCount,
  }: {
    filter?: string;
    subscriptionId?: string;
    permissions?: Array<string>;
    role?: number;
    onlyLockedUsers?: boolean;
    sorting?: string;
    maxResultCount?: number;
    skipCount?: number;
  }): CancelablePromise<AjaxResponseOfPagedResultDtoOfUserListForSubscriptionDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/user",
      query: {
        filter: filter,
        subscriptionId: subscriptionId,
        permissions: permissions,
        role: role,
        onlyLockedUsers: onlyLockedUsers,
        sorting: sorting,
        maxResultCount: maxResultCount,
        skipCount: skipCount,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteUser({ id }: { id?: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/front-end/v1/user",
      query: {
        id: id,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateUser({
    requestBody,
  }: {
    requestBody?: CreateOrUpdateUserInput;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/front-end/v1/user",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public createUser({
    requestBody,
  }: {
    requestBody?: CreateOrUpdateUserInput;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/user",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfGetUserForEditOutput
   * @throws ApiError
   */
  public getUser({
    userId,
  }: {
    userId: number;
  }): CancelablePromise<AjaxResponseOfGetUserForEditOutput> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/user/{userId}",
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public syncUserFromUi(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/user/sync-from-ui",
    });
  }
}
