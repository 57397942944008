/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfGetRoleForEditOutput } from "../models/AjaxResponseOfGetRoleForEditOutput";
import type { AjaxResponseOfListResultDtoOfRoleListDto } from "../models/AjaxResponseOfListResultDtoOfRoleListDto";
import type { CreateOrUpdateRoleInput } from "../models/CreateOrUpdateRoleInput";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class RoleAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any Success
   * @throws ApiError
   */
  public createRole({
    requestBody,
  }: {
    requestBody?: CreateOrUpdateRoleInput;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/roles",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfListResultDtoOfRoleListDto
   * @throws ApiError
   */
  public getRoles({
    permissions,
  }: {
    permissions?: Array<string>;
  }): CancelablePromise<AjaxResponseOfListResultDtoOfRoleListDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/roles",
      query: {
        permissions: permissions,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateRole({
    requestBody,
  }: {
    requestBody?: CreateOrUpdateRoleInput;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/front-end/v1/roles",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteRole({ id }: { id?: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/front-end/v1/roles",
      query: {
        id: id,
      },
    });
  }

  /**
   * @returns AjaxResponseOfGetRoleForEditOutput
   * @throws ApiError
   */
  public getRoleById({
    id,
  }: {
    id: number;
  }): CancelablePromise<AjaxResponseOfGetRoleForEditOutput> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/roles/{id}",
      path: {
        id: id,
      },
    });
  }
}
