/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { AxiosHttpRequest } from "./core/AxiosHttpRequest";

import { CredentialsAppService } from "./services/CredentialsAppService";
import { PerfilClicksAppService } from "./services/PerfilClicksAppService";
import { PermissionsAppService } from "./services/PermissionsAppService";
import { ProfessionsAppService } from "./services/ProfessionsAppService";
import { RoleAppService } from "./services/RoleAppService";
import { SegmentsAppService } from "./services/SegmentsAppService";
import { SessionAppService } from "./services/SessionAppService";
import { SubscriptionAppService } from "./services/SubscriptionAppService";
import { TokenAuthAppService } from "./services/TokenAuthAppService";
import { UserAppService } from "./services/UserAppService";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class SyncClient {
  public readonly credentials: CredentialsAppService;
  public readonly perfilClicks: PerfilClicksAppService;
  public readonly permissions: PermissionsAppService;
  public readonly professions: ProfessionsAppService;
  public readonly role: RoleAppService;
  public readonly segments: SegmentsAppService;
  public readonly session: SessionAppService;
  public readonly subscription: SubscriptionAppService;
  public readonly tokenAuth: TokenAuthAppService;
  public readonly user: UserAppService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "",
      VERSION: config?.VERSION ?? "frontend/v1",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.credentials = new CredentialsAppService(this.request);
    this.perfilClicks = new PerfilClicksAppService(this.request);
    this.permissions = new PermissionsAppService(this.request);
    this.professions = new ProfessionsAppService(this.request);
    this.role = new RoleAppService(this.request);
    this.segments = new SegmentsAppService(this.request);
    this.session = new SessionAppService(this.request);
    this.subscription = new SubscriptionAppService(this.request);
    this.tokenAuth = new TokenAuthAppService(this.request);
    this.user = new UserAppService(this.request);
  }
}
