import { SubscriberType, UserAssignedApp } from "@sync/utils/src/sync/api";
import { deleteCookie } from "cookies-next";
import { SYNC_CONSTS } from "src/config";
import { NavItem, NavSection } from "src/layouts/dashboard/navbar/NavConfig";

export const hasAssignedApp = (
  appPermissions: string[],
  permissionName: string
) => appPermissions.includes(permissionName);

export const hasPermission = (
  appPermissions: string[],
  permissionName: string
) => appPermissions.includes(permissionName);

export const getAllUserApps = () => {
  const result =
    localStorage.getItem(
      `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skUserAppsSufix}`
    ) !== null
      ? JSON.parse(
          localStorage.getItem(
            `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skUserAppsSufix}`
          ) ?? ""
        )
      : null;
  return result?.map((x: any) => x.appId);
};

//Foi feito assim para não ter de especificar dos hooks de permissions, ex: InteradorPermission/syncPermissions... etc
export const getAllUserPermissions = () => {
  const allUSerPermissionSessionKeys = getAllUserPermissionKeys();
  const allUserPermission = [];
  for (const sessionKey of allUSerPermissionSessionKeys) {
    const permissions =
      localStorage.getItem(sessionKey) != null
        ? JSON.parse(localStorage.getItem(sessionKey) ?? "")
        : undefined;

    if (permissions != undefined) {
      allUserPermission.push(permissions.items.map((x: any) => x.name));
    }
  }
  return allUserPermission;
};

export const removeAllAuthenticationCookies = () => {
  const allCookieKeys = getAllCookieKeys();
  for (const cookieKey of allCookieKeys) {
    deleteCookie(cookieKey);
  }
};

export const removeAllAuthenticationStorage = () => {
  const allSessionKeys = getAllSessionKeys();
  for (const sessionKey of allSessionKeys) {
    deleteLocalStorage(sessionKey);
  }
};

export const setLocalStorage = (key: string, obj: any) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const deleteLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

const getAllUserPermissionKeys = () => {
  const result: string[] = [];
  for (const [, value] of Object.entries(SYNC_CONSTS.appUniqueName)) {
    result.push(value + SYNC_CONSTS.skUserPermissionSufix);
  }
  return result;
};

const getAllSessionKeys = () => {
  const result: string[] = [];
  for (const [, value] of Object.entries(SYNC_CONSTS.appUniqueName)) {
    result.push(value + SYNC_CONSTS.skUserSufix);
    result.push(value + SYNC_CONSTS.skUserPermissionSufix);
    result.push(value + SYNC_CONSTS.skUserVendorSufix);
    result.push(value + SYNC_CONSTS.skUserAppsSufix);
  }
  return result;
};

const getAllCookieKeys = () => {
  const result: string[] = [];
  for (const [, value] of Object.entries(SYNC_CONSTS.appUniqueName)) {
    result.push(value + SYNC_CONSTS.authTokenSufix);
  }
  return result;
};

export function extractPermissions(navConfig: NavSection[]): string[] {
  const permissions: string[] = [];

  function recursiveExtractPermissions(items: NavItem[]) {
    for (const item of items) {
      if (item.permission) {
        permissions.push(item.permission);
      }
      if (item.children) {
        recursiveExtractPermissions(item.children);
      }
    }
  }

  for (const section of navConfig) {
    recursiveExtractPermissions(section.items);
  }

  return permissions;
}

export function filterNavConfigByUserPermissions(
  navConfig: NavSection[],
  userPermissions: string[],
  apps: UserAssignedApp[],
  subscriberType: SubscriberType
): NavSection[] {
  const appIds = apps.map((i) => i?.appId);
  function recursiveFilterItems(items: NavItem[]): NavItem[] {
    return items
      .filter((item) =>
        item?.appId != undefined &&
        item?.appId != SYNC_CONSTS.appUniqueName.sync
          ? ((!item?.permission ||
              userPermissions?.includes(item?.permission)) &&
              appIds.includes(item?.appId)) ||
            appIds.includes(item?.appId)
          : subscriberType == SubscriberType._0
          ? undefined
          : item
      )
      .map((item) => ({
        ...item,
        children: item?.children
          ? recursiveFilterItems(item?.children)
          : undefined,
      }));
  }

  return navConfig
    .map((section) => ({
      ...section,
      items: recursiveFilterItems(section?.items),
    }))
    .filter((section) => section?.items?.length > 0);
}
