/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfListOfComboboxItemDto } from "../models/AjaxResponseOfListOfComboboxItemDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ProfessionsAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfListOfComboboxItemDto
   * @throws ApiError
   */
  public getProfessionsCombo({
    professionId,
  }: {
    professionId?: number;
  }): CancelablePromise<AjaxResponseOfListOfComboboxItemDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/professions/options",
      query: {
        professionId: professionId,
      },
    });
  }
}
