/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfListOfSubscriptionDto } from "../models/AjaxResponseOfListOfSubscriptionDto";
import type { AjaxResponseOfSubscriptionDto } from "../models/AjaxResponseOfSubscriptionDto";
import type { CreateSubscriptionDto } from "../models/CreateSubscriptionDto";
import type { UpdateSubscriptionDto } from "../models/UpdateSubscriptionDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class SubscriptionAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfListOfSubscriptionDto
   * @throws ApiError
   */
  public getAll(): CancelablePromise<AjaxResponseOfListOfSubscriptionDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/subscription",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody?: CreateSubscriptionDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/subscription",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public update({
    requestBody,
  }: {
    requestBody?: UpdateSubscriptionDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/front-end/v1/subscription",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfSubscriptionDto
   * @throws ApiError
   */
  public getSubscriptionByStripeId({
    subscriptionId,
  }: {
    subscriptionId: string;
  }): CancelablePromise<AjaxResponseOfSubscriptionDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/subscription/stripe/{subscriptionId}",
      path: {
        subscriptionId: subscriptionId,
      },
    });
  }

  /**
   * @returns AjaxResponseOfSubscriptionDto
   * @throws ApiError
   */
  public getSubscriptionBySyncId({
    subscriptionId,
  }: {
    subscriptionId: string;
  }): CancelablePromise<AjaxResponseOfSubscriptionDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/subscription/sync/{subscriptionId}",
      path: {
        subscriptionId: subscriptionId,
      },
    });
  }
}
