/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfListOfUserAssignedApp } from "../models/AjaxResponseOfListOfUserAssignedApp";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class CredentialsAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfListOfUserAssignedApp
   * @throws ApiError
   */
  public getAllApps(): CancelablePromise<AjaxResponseOfListOfUserAssignedApp> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/credentials/get-all",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public createSiteCredential(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/front-end/v1/credentials",
    });
  }
}
