/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfAuthenticateResultModel } from "../models/AjaxResponseOfAuthenticateResultModel";
import type { AjaxResponseOfExternalAuthenticateResultModel } from "../models/AjaxResponseOfExternalAuthenticateResultModel";
import type { AjaxResponseOfImpersonatedAuthenticateResultModel } from "../models/AjaxResponseOfImpersonatedAuthenticateResultModel";
import type { AjaxResponseOfListOfExternalLoginProviderInfoModel } from "../models/AjaxResponseOfListOfExternalLoginProviderInfoModel";
import type { AjaxResponseOfRefreshTokenResult } from "../models/AjaxResponseOfRefreshTokenResult";
import type { AjaxResponseOfSwitchedAccountAuthenticateResultModel } from "../models/AjaxResponseOfSwitchedAccountAuthenticateResultModel";
import type { AuthenticateModel } from "../models/AuthenticateModel";
import type { ExternalAuthenticateModel } from "../models/ExternalAuthenticateModel";
import type { SendTwoFactorAuthCodeModel } from "../models/SendTwoFactorAuthCodeModel";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class TokenAuthAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfAuthenticateResultModel
   * @throws ApiError
   */
  public authenticate({
    requestBody,
  }: {
    requestBody?: AuthenticateModel;
  }): CancelablePromise<AjaxResponseOfAuthenticateResultModel> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/Authenticate",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfRefreshTokenResult
   * @throws ApiError
   */
  public refreshToken({
    refreshToken,
  }: {
    refreshToken?: string;
  }): CancelablePromise<AjaxResponseOfRefreshTokenResult> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/RefreshToken",
      query: {
        refreshToken: refreshToken,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public logOut(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/TokenAuth/LogOut",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public sendTwoFactorAuthCode({
    requestBody,
  }: {
    requestBody?: SendTwoFactorAuthCodeModel;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/SendTwoFactorAuthCode",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns AjaxResponseOfImpersonatedAuthenticateResultModel
   * @throws ApiError
   */
  public impersonatedAuthenticate({
    impersonationToken,
  }: {
    impersonationToken?: string;
  }): CancelablePromise<AjaxResponseOfImpersonatedAuthenticateResultModel> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/ImpersonatedAuthenticate",
      query: {
        impersonationToken: impersonationToken,
      },
    });
  }

  /**
   * @returns AjaxResponseOfImpersonatedAuthenticateResultModel
   * @throws ApiError
   */
  public delegatedImpersonatedAuthenticate({
    userDelegationId,
    impersonationToken,
  }: {
    userDelegationId?: number;
    impersonationToken?: string;
  }): CancelablePromise<AjaxResponseOfImpersonatedAuthenticateResultModel> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/DelegatedImpersonatedAuthenticate",
      query: {
        userDelegationId: userDelegationId,
        impersonationToken: impersonationToken,
      },
    });
  }

  /**
   * @returns AjaxResponseOfSwitchedAccountAuthenticateResultModel
   * @throws ApiError
   */
  public linkedAccountAuthenticate({
    switchAccountToken,
  }: {
    switchAccountToken?: string;
  }): CancelablePromise<AjaxResponseOfSwitchedAccountAuthenticateResultModel> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/LinkedAccountAuthenticate",
      query: {
        switchAccountToken: switchAccountToken,
      },
    });
  }

  /**
   * @returns AjaxResponseOfListOfExternalLoginProviderInfoModel
   * @throws ApiError
   */
  public getExternalAuthenticationProviders(): CancelablePromise<AjaxResponseOfListOfExternalLoginProviderInfoModel> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/TokenAuth/GetExternalAuthenticationProviders",
    });
  }

  /**
   * @returns AjaxResponseOfExternalAuthenticateResultModel
   * @throws ApiError
   */
  public externalAuthenticate({
    requestBody,
  }: {
    requestBody?: ExternalAuthenticateModel;
  }): CancelablePromise<AjaxResponseOfExternalAuthenticateResultModel> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/TokenAuth/ExternalAuthenticate",
      body: requestBody,
      mediaType: "application/json-patch+json",
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public testNotification({
    message = "",
    severity = "info",
  }: {
    message?: string;
    severity?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/TokenAuth/TestNotification",
      query: {
        message: message,
        severity: severity,
      },
    });
  }
}
