/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AjaxResponseOfGetCurrentLoginInformationsOutput } from "../models/AjaxResponseOfGetCurrentLoginInformationsOutput";
import type { AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto } from "../models/AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto";
import type { AjaxResponseOfUserLoginInfoDto } from "../models/AjaxResponseOfUserLoginInfoDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class SessionAppService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AjaxResponseOfGetCurrentLoginInformationsOutput
   * @throws ApiError
   */
  public getCurrentLoginInformations(): CancelablePromise<AjaxResponseOfGetCurrentLoginInformationsOutput> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/sessions/get-current-login-informations",
    });
  }

  /**
   * @returns AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto
   * @throws ApiError
   */
  public getAllPermissions(): CancelablePromise<AjaxResponseOfListResultDtoOfFlatPermissionWithLevelDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/sessions/get-all-permissions",
    });
  }

  /**
   * @returns AjaxResponseOfUserLoginInfoDto
   * @throws ApiError
   */
  public getCurrentUserInfo(): CancelablePromise<AjaxResponseOfUserLoginInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/front-end/v1/sessions/get-current-user",
    });
  }
}
