/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useAuth0 as _useAuth0 } from "@auth0/auth0-react";
import { SyncClient as _syncClient } from "@sync/utils";
import {
  GetCurrentLoginInformationsOutput,
  ListResultDtoOfFlatPermissionWithLevelDto,
  UserAssignedApp,
  UserLoginInfoDto,
} from "@sync/utils/src/sync/api";
import { getCookie, setCookie } from "cookies-next";
import { SYNC_CONSTS } from "src/config";
import {
  removeAllAuthenticationCookies,
  removeAllAuthenticationStorage,
  setLocalStorage,
} from "src/utils/authentication";

export const syncSessionKeys = {
  user: `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skUserSufix}`,
  userPermission: `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skUserPermissionSufix}`,
  userApps: `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skUserAppsSufix}`,
  grantedPermissions: `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.skGrantedPermissionsSufix}`,
  subscription: `${SYNC_CONSTS.appUniqueName.sync}${SYNC_CONSTS.subscriptionsSufix}`,
};

const tokenName = SYNC_CONSTS.appUniqueName.sync + SYNC_CONSTS.authTokenSufix;

export type SyncClient = InstanceType<typeof _syncClient>;

export const useClientSync = (skipAuthTokenCheck?: boolean) => {
  const authToken: string | undefined = getCookie(tokenName)?.toString() as any;

  if (skipAuthTokenCheck) {
    return new _syncClient({
      BASE: SYNC_CONSTS.apiBaseAddress,
      TOKEN: authToken,
    });
  }

  if (!authToken) {
    const token = getAccessToken();
    return new _syncClient({
      BASE: SYNC_CONSTS.apiBaseAddress,
      TOKEN: token!,
    });
  } else {
    return new _syncClient({
      BASE: SYNC_CONSTS.apiBaseAddress,
      TOKEN: authToken,
    });
  }
};

export const useSyncSession = () => {
  if (typeof localStorage == "undefined") {
    return {
      user: null,
      permissions: [],
      apps: [],
    };
  }
  let userParsed = JSON.parse(
    localStorage.getItem(syncSessionKeys.user) as string
  );
  let userPermissionParsed = JSON.parse(
    localStorage.getItem(syncSessionKeys.userPermission) as string
  );
  const userLinkedApps = JSON.parse(
    localStorage.getItem(syncSessionKeys.userApps) as string
  );

  if (
    userParsed === null ||
    userPermissionParsed === null ||
    userLinkedApps === null
  ) {
    userParsed = JSON.parse(
      localStorage.getItem(syncSessionKeys.user) as string
    );
    userPermissionParsed = JSON.parse(
      localStorage.getItem(syncSessionKeys.userPermission) as string
    );
  }

  const result: GetCurrentLoginInformationsOutput = {
    user: userParsed as UserLoginInfoDto,
    permissions:
      userPermissionParsed as ListResultDtoOfFlatPermissionWithLevelDto,
    apps: userLinkedApps as UserAssignedApp[],
  };

  return result;
};

function getAccessToken() {
  const { getAccessTokenSilently, user, logout } = _useAuth0();
  function callLogout() {
    logout({ returnTo: window.location.origin });
  }
  let authToken = "";
  getAccessTokenSilently({ detailedResponse: true }).then((data) => {
    const tempClient = new _syncClient({
      BASE: SYNC_CONSTS.apiBaseAddress,
    });
    const userSession = user;
    const requestBody = {
      authProvider: "OpenIdConnect",
      providerKey: userSession?.sub as string,
      providerAccessCode: data.id_token,
    };
    if (!requestBody.providerKey) return;
    tempClient.tokenAuth
      .externalAuthenticate({ requestBody: requestBody })
      .then(async (response) => {
        setCookie(tokenName, response.result?.accessToken, {
          maxAge: response.result?.expireInSeconds,
        });
        authToken = response.result?.accessToken!;
        tempClient.request.config.TOKEN = response.result
          ?.accessToken as string;
        tempClient.session
          .getCurrentLoginInformations()
          .then((response) => {
            //Ao entrar deve renovar localStorage
            //Renova
            setLocalStorage(syncSessionKeys.user, response.result?.user!);
            setLocalStorage(
              syncSessionKeys.userPermission,
              response.result?.permissions!
            );
            setLocalStorage(syncSessionKeys.userApps, response.result?.apps!);
            setLocalStorage(
              syncSessionKeys.grantedPermissions,
              response.result?.grantedPermissions ?? []
            );
            localStorage.setItem(
              syncSessionKeys.subscription,
              JSON.stringify(response.result?.subscriptions)
            );
            return authToken;
          })
          .catch((err) => console.error(err, "err"));
      })
      .catch((err: any) => {
        removeAllAuthenticationCookies();
        removeAllAuthenticationStorage();
        setTimeout(callLogout, 5000);
      });
  });
}
